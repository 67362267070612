<template>
    <div class="project-container">
        <h3 class="tab-title">My Creative Process</h3>
    </div>
    <p class="tagline">As a creative wearing many hats, it is imperative for me to understand my clients' mission and needs,
        and come up with an appropriate budget and timeline.  Below illustrates what you can expect from my work
        each step of the way, from brainstorming to deployment.  By each of us understanding our needs, I can ensure
        that expectations are realistic and can be met. 
    </p>
    <div>
        <div class="process-container"> 
        <div class="process-item">
            <ProcessComponent 
                title="PHASE I"  
                description="Consultation"
                src="communicate-icon"
                paragraph="A consultation includes exchanging information to understand the client's goals.  We will discuss
                the needs for the project including color, layout, graphics, and typography.  Anything shared goes a long way, and
                I encourage clients to bring visuals of projects that possess relative attributes for inspiration and brainstorming."
            />
        </div>
        <div class="process-item">
            <ProcessComponent 
                title="PHASE II"
                description="Proposal | Estimate"
                src="proposal-icon"
                paragraph="To ensure we are on the same page as we move forward, a detailed estimate and proposal will be provided for the client.
                At this stage we can manage expectations, carve out a manageable timeline, altar details and make necessary compromises.  Plans for payment will
                also be illustrated here (project lump sum or as the project goes on, for instance).  Once the client and I are in full understanding,
                the next phase can begin."
            />
        </div>
        <div class="process-item">
            <ProcessComponent 
                title="PHASE III"
                description="Mockups and Wireframing"
                src="wireframe-icon"
                paragraph="A mockup is a visual representation of the finished product, whereas wireframing shows the functionality of the product (such as a website).
                Based upon the client's proposal, various mockups will be presented, the favorite of which will then also receive a wireframed model.  Once these are
                approved by the client, development will begin.  This is also a good opportunity for the client to ask for any changes (the amount of changes will depend upon
                the client's proposal), and to make sure details are correct."
            />
        </div>
        <div class="process-item">
            <ProcessComponent 
                title="PHASE IV"
                description="Development"
                src="development-icon"
                paragraph="Full construction and development of the project; this includes building graphics and assets, as well as developing code for the website.  
                This is the longest step of the process, will include check-ins based upon client's personalized proposal."
            />
        </div>
        <div class="process-item">
            <ProcessComponent 
                title="PHASE V"
                description="Final Client Approvals"
                src="approval-icon"
                paragraph="I showcase the project to the client for final approvals and changes.  The amount of changes will be dependent upon the client's proposal."
            />
        </div>
        <div class="process-item">
            <ProcessComponent 
                title="PHASE VI"
                description="Project Deployment"
                src="deployment-icon"
                paragraph="The project goes live and becomes accessible to the client's audience.  May include additional debugging as necessary."
            />
        </div>
        <div class="process-item">
            <ProcessComponent 
                title="PHASE VII"
                description="Maintenance and Updates"
                src="maintenance-icon"
                paragraph="After the project is finished, clients have the choice to pay a monthly fee for maitenance and continued updates illustrated in the
                proposal.  If deemed unnecessary, the client is free to outsource updates and maitenance to a 3rd party provider."
            />
        </div>
    </div>
    </div>
    <div class="flex-column">
        <div class="flex-row">
            <router-link to="/Projects">
            <button class="retro-button">
            View Projects
            </button>
        </router-link>
        <a href="mailto:kirstyn.blandy@gmail.com">
            <button class="retro-button">
                <font-awesome-icon icon="fa-regular fa-envelope" style="color: #000000;" />
                Schedule consultation
            </button>
        </a>
        </div>
    </div>
</template>

<script>
import ProcessComponent from './ProcessComponent.vue'

export default {
    name: 'AllProcesses',
    components: {
        ProcessComponent,
    }
}
</script>

<style lang="scss" scoped>
//sass typefaces
$primary-font: 'Roboto Mono', monospace;
$font-special: 'Urbanist', sans-serif;
$font-typewriter: 'Courier Prime', monospace;

//sass colors
$primary-color: black;
$secondary-color: gray;
$accent-color: rgb(49, 49, 49);

p {
    text-align: left;
}
.process-container {
    margin-top: 20px;
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.process-item {
    max-width: 360px;
    height: auto;
    text-align: center;
    margin-bottom: 10px;
    padding: 20px;
    border-bottom: 1px solid $secondary-color;
}
.tab-title {
    font-size: 10px;
    border-bottom: 1px dotted $secondary-color;
}
</style>