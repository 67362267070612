<template>
    <div class="heading">
        <h2 class="tiny-title">{{ this.title }}:  </h2>
        <h3 class="big-title">{{this.description}}</h3>
    </div>
    <div class="flex-container">
        <div class="img-container">
            <img :src="`../../Projects-assets/themes/monocrome/${src}.png`" class="process-icon">
        </div>
        <div>
            <p>{{this.paragraph}}</p>
        </div>
    </div>
</template>

<script>
// import brain from '../../public/Projects-assets/brain.png'
export default {
    name: 'ProcessComponent',
    props: {
        title: String,
        description: String,
        src: String,
        paragraph: String,
    }
}
</script>

<style lang="scss" scoped>
//sass typefaces
$primary-font: 'Roboto Mono', monospace;
$font-special: 'Urbanist', sans-serif;
$font-typewriter: 'Courier Prime', monospace;

//sass colors
$primary-color: black;
$secondary-color: gray;
$accent-color: rgb(49, 49, 49);
.big-title {
    font-size: 14px;
}
.flex-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 80%;
    text-align: left;
}
.img-container {
    height: 100px;
    width: 100px;
    margin: auto;
}
.process-icon {
    width: 100%;
    height: 100%;
    align-self: baseline;
}
.tiny-title {
    font-size: 12px;
    text-transform: capitalize;
}
</style>