<template>
    <div class="flex-container">
        <div class="flex-row">
            <h2 :class="title">{{ title }}</h2>
            <a :href="`${url}`" target="_blank">
                <font-awesome-icon :icon="`fa-brands ${iconLink}`" size="xl" class="icon" />
            </a>
        </div>
        <h3>{{ description }}</h3>
        <a :href="`${url}`" target="_blank">
            <div class="preview-container">
                <img :src="`../../Projects-assets/${src}.png`" class="project-preview" :alt="`${alt}`">
            </div>
        </a>
        <div class="flex-row">
            <div class="paragraph-text">
                <p :paragraph="paragraph">{{ paragraph }}</p>
                <p :paragraph2="paragraph2">{{ paragraph2 }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProjectComponent',
    props: {
        title: String,
        src: String,
        srcOther: String,
        description: String,
        paragraph: String,
        paragraph2: String,
        iconFramework: String,
        iconGithub: String,
        iconLink: String,
        alt: String,
        altOther: String,
        url: String,
    }
}
</script>

<style lang="scss" scoped>
//sass typefaces
$primary-font: 'Roboto Mono', monospace;
$font-special: 'Urbanist', sans-serif;
$font-typewriter: 'Courier Prime', monospace;

//sass colors
$primary-color: black;
$secondary-color: gray;
$accent-color: rgb(49, 49, 49);

.flex-container {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.paragraph-text {
    max-width: 400px;
    padding-left: 10px;
    text-align: left;
}
.preview-container {
    margin: auto;
}
.project-preview {
    margin: auto;
    // width: 200px;
    width: 80%;
    height: auto;
    object-fit: cover;
    text-align: center;
}
.icon {
    color: $primary-color;
    padding: 14px 8px 0;
}

</style>