<template>
    <div class="project-header">
        <div class="flexbox">
            <font-awesome-icon icon="fa-solid fa-cube" size="lg" class="icon" />
            <h2>Adobe Creative Cloud</h2>
            <font-awesome-icon icon="fa-solid fa-cube" size="lg" class="icon" />
            <a href="https://www.adobe.com/" target="_blank">docs</a>
        </div>
    </div>
    <div>
        <GDProjectComponent 
            title="Memorial Program" 
            paragraph1a="This memorial program was designed for the loved one who was a fisherman and spent his best days out in nature.  I wanted the program to reflect that with a lovely water-color style digital collage. "
            paragraph2a="The interior of the program is soft and understated with lots of whitespace.  A continuation of the fishing theme can be seen in the shape of waves below the loved one's name."
            iconFramework=fa-cube
            alt="Peaceful watercolor of a lake surrounded by forest and a single, simple, small, empty fishing boat quietly drifts across the water.  Birds fly in the distance."
            alt2="Program with generic text"
            src="funeral-program"
            src2="funeral-program2"
            />
        <div class="centered-text">
            <h5>Tools Utilized:</h5>
            <div class="flex-row tools">
                <h4 class="skill button-decoration">InDesign</h4>
                <h4 class="skill button-decoration">Photoshop</h4>
                <h4 class="skill button-decoration">Adobe Express</h4>
            </div>
        </div>
        <div class="decorative-line"></div>
    </div>
    <div>
        <GDProjectComponent 
            title="The Salvage Archive: Isolation Tank Ensemble Zine" 
            paragraph1a="This is the front cover of a small, 8 page magazine I designed for prog metal band Isolation Tank Ensemble using their art and logos."
            paragraph2a="This is the magazine's back cover, and the QR code brings you to the band's online store."
            iconFramework=fa-cube
            alt="Cthulu-inspired psychonaut drifts through space, isolation tank logo at the top, and text 'extraction 1: the Salvage Archive'"
            alt2="A very cute tardigrade floats in space"
            src="iso-zine"
            src2="iso-zine8"
            />
        <div class="centered-text">
            <h5>Tools Utilized:</h5>
            <div class="flex-row tools">
                <h4 class="skill button-decoration">InDesign</h4>
                <h4 class="skill button-decoration">Photoshop</h4>
                <h4 class="skill button-decoration">Adobe Express</h4>
            </div>
        </div>
        <div class="decorative-line"></div>
    </div>
</template>

<script>
import GDProjectComponent from '../GDProjectComponent.vue';


export default {
    name: 'AdobeCCProjects',
    components: {
        GDProjectComponent,
    },
}
</script>

<style lang="scss" scoped>
//sass typefaces
$primary-font: 'Roboto Mono', monospace;
$font-special: 'Urbanist', sans-serif;
$font-typewriter: 'Courier Prime', monospace;

//sass colors
$primary-color: black;
$secondary-color: gray;
$accent-color: rgb(49, 49, 49);

a {
align-self: center;
margin-left: 20px;
padding: 4px;

text-decoration: none;
list-style: none;
color: black;

border: 1px solid gray;
border-radius: 10px;
}
.button-decoration {
    align-self: center;
    margin-left: 20px;
    padding: 4px;

    text-decoration: none;
    list-style: none;
    color: black;

    border: 1px solid gray;
    border-radius: 10px;
}

.flexbox {
    display: flex;
    flex-direction: row;
}
.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.skill {
    padding: 6px;
}

</style>