<template>
    <div class="container">
        <div class="card">
        <div class="from-top">
            <div class="header">
                <div class="header-flex">
                    <img class="avatar" src="../../public/Projects-assets/themes/monocrome/astronaut-with-credit.png" alt="AI-created astronaut woman with round dark goggles">
                    <div class="sub-avatar">
                        <h2 class="title">KIRSTYN  BLANDY </h2>
                        <h2 class="subtitle">kē-ər-stin blan(d)ē (noun, proper)</h2>
                    </div>
                </div>
                <h2 class="decorative-line">web designer and developer</h2>
            </div>
            <div>
                <router-link to="/Projects">
                    <button class="retro-button">
                        <font-awesome-icon icon="fa-solid fa-rocket" style="color: #000000;" />
                        Projects</button>
                </router-link>
                <a href="mailto:kirstyn.blandy@gmail.com">
                    <button class="retro-button">
                        <font-awesome-icon icon="fa-regular fa-envelope" style="color: #000000;" />
                        Contact
                    </button>
                </a>
            </div>
            <div class="flex-column2">
                <p class="bullet">I develop custom-made websites, digital assets, and printed materials for small businesses from 
                inception to deployment, using each company's mission to spearhead responsive front-facing creative decisions. Excited by the world of technology, I look 
                forward to continuing my contribution as a futurist to create a more meaningful digital society in order to preserve the human component that gives content 
                life: asset by asset, commit by commit.
                </p>
                <!-- <p class="bullet"><span class="border-stylized">By Night: </span>  I am a violinist in the Isolation Tank Ensemble creating cinematic progressive trash prog sounds.  Love it or hate it, <a href="https://isolationtankensemble1.bandcamp.com/album/heliography" target="_blank"><span class="border-stylized">Heliography</span></a> is unlike anything you've ever heard before.</p> -->
            </div>
        </div>
    </div>
    <div class="decorative">
        <h4 class="boarding-pass">Current Location >>> EARTH</h4>
    </div>
    </div>
</template>

<script>
export default {
    name: 'AboutComopnent',
}
</script>

<style lang="scss" scoped>

//sass typefaces
$primary-font: 'Roboto Mono', monospace;
$font-special: 'Urbanist', sans-serif;
$font-typewriter: 'Courier Prime', monospace;

//sass colors
$primary-color: black;
$secondary-color: gray;
$accent-color: rgb(49, 49, 49);

//sass font-sizes

$small: 12px;
$medium: 16px;
$large: 20px;

h2 {
    padding: 0;
    font-weight: lighter;
}
.avatar {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    justify-self: flex-start;
    padding-top: 8px;
    margin-right: 20px;
}
.boarding-pass {
    margin-right: 10px;
    text-align: right;
    
}
.bullet {
    font-size: $small;
    font-family: $primary-font;
    font-weight: 400;
    text-align: left;
}
.container {
    background-size: fill;
    background-position: top;
    width: 100%;
}
.decorative {
    height: 50px;
    width: 100%;
    border-top: 1px dotted $accent-color;
    font-family: $font-typewriter;
    font-size: 10px;
    color: $accent-color;
}

.decorative-line {
    border-top: 1px dashed $secondary-color;
    padding-top: 5px;
    color: $accent-color;
}
.flex-column2 {
    display: flex;
    flex-direction: column;
    justify-content: right;
    padding: 5px 0 10px;
    max-width: 500px;
    margin: auto;
}
.from-top {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.header {
    margin: auto;
    border: 1px dotted rgba(0, 0, 0, 0.531);
    width: 100%;
}
.header-flex {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.subtitle {
    font-weight: bolder;
    font-size: $small;
    margin-top: -28px;
    color: $accent-color;
}
.sub-avatar {
    text-align: left;
}
.title {
    font-weight: 400;
    font-size: 20px;
    font-family: $font-typewriter;
    letter-spacing: 1px;
    color: $accent-color;
    padding-bottom: 5px;
}

span {
    display: inline-block;
}
.border-stylized {
    font-weight: bolder;
    border: 1px solid $primary-color;
    padding: 4px;
}
</style>