<template>
    <div class="project-header">
        <div class="flexbox">
            <font-awesome-icon icon="fa-brands fa-react" size="lg" class="icon" />
            <h2>REACT</h2>
            <font-awesome-icon icon="fa-brands fa-react" size="lg" class="icon" />
            <a href="https://react.dev/learn" target="_blank">docs</a>
        </div>
    </div>
    <div>
        <ProjectComponent 
            title="DoublePlus Shopping List" 
            paragraph="This is a simple project built during Code Louisville Instruction.  Halfway through the semester, Covid-19 hit.  I found inspiration through the widespread panic and uncertainty to give the project a 'tongue-in-cheek' marriage between the Pandemic and George Orwell's '1984', and completed the project without in-class instruction."
            paragraph2="While the Covid-19 API I had accessed is no longer in service, the Edaname food API still works and allows the user to add and delete items in customized lists saved to Local Storage.  To view/access functionality, feel free to click on the project, which will take you to the github repository."
            src="shopping"
            iconFramework=fa-react
            iconLink=fa-github
            alt="Newspaper-stylized website screenshot"
            url="https://github.com/kblandy/grocery-app-CL"
            />
        <div class="centered-text">
            <h5>Tools Utilized:</h5>
            <div class="flex-row tools">
                <h4 class="skill button-decoration">React</h4>
                <h4 class="skill button-decoration">CSS</h4>
                <h4 class="skill button-decoration">HTML</h4>
                <h4 class="skill button-decoration">Bootstrap</h4>
            </div>
        </div>
        <div class="decorative-line"></div>
    </div>
</template>

<script>
import ProjectComponent from '../ProjectComponent.vue';

export default {
    name: 'ReactProjects',
    components: {
        ProjectComponent,
    },
}
</script>

<style lang="scss" scoped>
//sass typefaces
$primary-font: 'Roboto Mono', monospace;
$font-special: 'Urbanist', sans-serif;
$font-typewriter: 'Courier Prime', monospace;

//sass colors
$primary-color: black;
$secondary-color: gray;
$accent-color: rgb(49, 49, 49);

a {
    align-self: center;
    margin-left: 20px;
    padding: 4px;

    text-decoration: none;
    list-style: none;
    color: $primary-color;

    border: 1px solid $secondary-color;
    border-radius: 10px;
}
.button-decoration {
    align-self: center;
    margin-left: 20px;
    padding: 4px;

    text-decoration: none;
    list-style: none;
    color: $primary-color;

    border: 1px solid $secondary-color;
    border-radius: 10px;
}
.flexbox {
    display: flex;
    flex-direction: row;
}
.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.skill {
    padding: 6px;
}

</style>