<template>
  <div id="app">
    <MainPage />
  </div>
</template>

<script>
import MainPage from './components/MainPage.vue';

export default {
  name: 'App',
  components: {
    MainPage,
  },
}
</script>

<style lang="scss" global>

//sass typefaces
$primary-font: 'Roboto Mono', monospace;
$font-special: 'Urbanist', sans-serif;
$font-typewriter: 'Courier Prime', monospace;

//sass colors
$primary-color: black;
$secondary-color: gray;

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $primary-color;
}

#hidden {
  visibility: hidden;
}

html {
  scroll-behavior: smooth;
}

// a:active {
//   border: 1px dotted orange;
//   list-style: none;
// }

a:visited {
  text-decoration: none;
  color: $primary-color;
}
a {
    text-decoration: none;
    color: $primary-color;
}

button {
  border: none;
}
.button-decoration {
    align-self: center;
    margin: 4px;
    padding: 4px;
    text-decoration: none;
    list-style: none;
    color: $primary-color;
    border-radius: 10px;
}

.centered-text {
  text-align: center;
}

.decorative-line {
    border-top: 1px dotted $primary-color;
}

.icon {
    align-self: center;
    padding: 10px;
}

.project-header {
    border-bottom: 1px dotted $secondary-color;
}

.retro-button {
    font-family: $font-typewriter;
    background-color: white;
    margin: 10px;
    height: 60px;
    width: auto;
    box-shadow: 1px 1px 1px 1px $secondary-color;
  }

.tools {
  padding-bottom: 10px;
}
</style>
