<template>
    <div class="project-header">
        <div class="decorative-line"></div>
        <div class="flexbox">
            <font-awesome-icon icon="fa-brands fa-vuejs" size="lg" class="icon" />
            <h2>VUE</h2>
            <font-awesome-icon icon="fa-brands fa-vuejs" size="lg" class="icon" />
            <a class="button-decoration" href="https://vuejs.org/guide/introduction.html" target="_blank">docs</a>
        </div>
    </div>
    <div>            
        <ProjectComponent 
        title="Chaotic Good Records" 
        paragraph="Chaotic Good Records is a new record label established in 2023, who caters to musicians and artists in the Louisville Metro area."
        paragraph2="I designed this single-page responsive landing page to showcase featured artists and provide access to the Chaotic Good square space to purchase merchandise."
        src="chaotic-good-records-desktop" 
        iconFramework="fa-vuejs"
        iconLink="fa-staylinked"
        url="http://chaoticgoodrecords.com/"
        />
    <div class="centered-text project-header">
        <h5>Tools Utilized:</h5>
        <div class="flex-row tools">
            <h4 class="skill button-decoration">Vue</h4>
            <h4 class="skill button-decoration">CSS</h4>
            <h4 class="skill button-decoration">HTML</h4>
            <h4 class="skill button-decoration">SASS</h4>
        </div>
    </div>
    <ProjectComponent 
            title="Hillstead Farms" 
            paragraph="Hillstead Farms is a small permaculture farm that sells seasonal home-grown microgreens."
            paragraph2="I designed this single-page responsive landing page website with their core values in mind to showcase their various microgreens which are sold at a local Farmer's Market during summer."
            src="hillsteadfarmsky-desktop" 
            alt="Landing Page wesbite with image of peaceful farm, pond in forefront, barn in background"
            iconFramework="fa-vuejs"
            iconLink="fa-staylinked"
            url="http://hillsteadfarmsky.com/"
            />
    <div class="centered-text project-header">
        <h5>Tools Utilized:</h5>
        <div class="flex-row tools">
            <h4 class="skill button-decoration">Vue</h4>
            <h4 class="skill button-decoration">CSS</h4>
            <h4 class="skill button-decoration">HTML</h4>
        </div>
    </div>
    </div>
</template>

<script>
import ProjectComponent from '../ProjectComponent.vue';

export default {
    name: 'VueProjects',
    components: {
        ProjectComponent,
    },
}
</script>

<style lang="scss" scoped>
//sass typefaces
$primary-font: 'Roboto Mono', monospace;
$font-special: 'Urbanist', sans-serif;
$font-typewriter: 'Courier Prime', monospace;

//sass colors
$primary-color: black;
$secondary-color: gray;
$accent-color: rgb(49, 49, 49);

.button-decoration {
    align-self: center;
    margin-left: 20px;
    padding: 4px;

    text-decoration: none;
    list-style: none;
    color: $primary-color;

    border: 1px solid $secondary-color;
    border-radius: 10px;
}
.flexbox {
    display: flex;
    flex-direction: row;
}
.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.skill {
    padding: 6px;
}
</style>