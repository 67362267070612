<template>
    <div class="project-header">
        <div class="flexbox">
            <font-awesome-icon icon="fa-brands fa-react" size="lg" class="icon" />
            <h2>HTML/CSS</h2>
            <font-awesome-icon icon="fa-brands fa-react" size="lg" class="icon" />
            <a href="https://react.dev/learn" target="_blank">docs</a>
        </div>
    </div>
    <div>
        <ProjectComponent 
            title="SPA with parallax scrolling" 
            paragraph="This minimalist designed single page application sports a parallax scrolling effect featuring various space-themed stock photos."
            paragraph2="To view the project and its parallax effect, feel free to click on the project, which will take you to the github repository."
            src="parallax-html"
            srcMobile="parallax-html-mobile-full" 
            iconFramework=fa-html
            iconLink=fa-github
            alt="Newspaper-stylized website screenshot"
            url="https://github.com/kblandy/parallax_space_project"
            />
        <div class="centered-text">
            <h5>Tools Utilized:</h5>
            <div class="flex-row tools">
                <h4 class="skill button-decoration">HTML5</h4>
                <h4 class="skill button-decoration">CSS</h4>
            </div>
        </div>
        <div class="decorative-line"></div>
    </div>
</template>

<script>
import ProjectComponent from '../ProjectComponent.vue';

export default {
    name: 'FramelessProjects',
    components: {
        ProjectComponent,
    },
}
</script>

<style lang="scss" scoped>
//sass typefaces
$primary-font: 'Roboto Mono', monospace;
$font-special: 'Urbanist', sans-serif;
$font-typewriter: 'Courier Prime', monospace;

//sass colors
$primary-color: black;
$secondary-color: gray;
$accent-color: rgb(49, 49, 49);

a {
align-self: center;
margin-left: 20px;
padding: 4px;

text-decoration: none;
list-style: none;
color: black;

border: 1px solid gray;
border-radius: 10px;
}
.button-decoration {
    align-self: center;
    margin-left: 20px;
    padding: 4px;

    text-decoration: none;
    list-style: none;
    color: black;

    border: 1px solid gray;
    border-radius: 10px;
}

.flexbox {
    display: flex;
    flex-direction: row;
}
.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.skill {
    padding: 6px;
}

</style>