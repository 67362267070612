<template>
    <div class="project-container">
        <h2 class="tab-title">Projects</h2>
            <a href="#web-dev-projects">
                <button class="retro-button">
                    <div class="flex-column">
                        Web Development
                    <font-awesome-icon icon="fa-solid fa-laptop-code" size="lg" class="button-icon" />
                    </div>
                </button>
            </a>
            <a href="#graphic-design-projects">
                <button class="retro-button">
                <div class="flex-column">
                    Graphic Design
                <font-awesome-icon icon="fa-solid fa-icons" size="lg" class="button-icon" />
                </div>
            </button>
            </a>
            
    </div>
    <p class="tagline">Responsive minimalist designs built with practicality in mind</p>
    <div id="web-dev-projects">
        <VueProjects />
        <ReactProjects />
        <FramelessProjects />
    </div>
    <div id="graphic-design-projects">
        <AdobeCCProjects />
    </div>
    <div class="flex-column extra-padding">
        Click below to explore my work flow and creative process, 
        or email me to schedule a consultation and find out if your project and I will be a good fit together:
        <div class="flex-row centered">
            <router-link to="/Process">
            <button class="retro-button">
                <font-awesome-icon icon="fa-solid fa-satellite" style="color: #000000;" />
                Explore my Process
            </button>
        </router-link>
        <a href="mailto:kirstyn.blandy@gmail.com">
            <button class="retro-button">
                <font-awesome-icon icon="fa-regular fa-envelope" style="color: #000000;" />
                Contact
            </button>
        </a>
        </div>
        
    </div>
</template>

<script>
import VueProjects from './Projects/VueProjects.vue';
import ReactProjects from './Projects/ReactProjects.vue';
import FramelessProjects from './Projects/FramelessProjects.vue';
import AdobeCCProjects from './Projects/AdobeCCProjects.vue';

export default {
    data: () => ({ 
    vueClicked: false, 
    reactClicked: false,
    }),
    name: 'AllProjects',
    components: {
        VueProjects,
        ReactProjects,
        FramelessProjects,
        AdobeCCProjects,
    },
}
</script>

<style lang="scss" scoped>
//sass typefaces
$primary-font: 'Roboto Mono', monospace;
$font-special: 'Urbanist', sans-serif;
$font-typewriter: 'Courier Prime', monospace;

//sass colors
$primary-color: black;
$secondary-color: gray;
$accent-color: rgb(49, 49, 49);

.button-icon {
    padding-top: 4px;
}

.centered {
    text-align: center;
}
.extra-padding {
    padding: 20px 0 20px;
}
.flex-column {
    display: flex;
    flex-direction: column;
    text-align: left;
}
.project-container {
    font-family: $font-typewriter;
}

.tab-title {
    font-size: 10px;
    border-bottom: 1px dotted $secondary-color;
}
.tagline {
    text-align: center;
}
</style>