import { createRouter, createWebHistory } from 'vue-router';
import AboutComponent from '../components/AboutComponent.vue';
// import ProcessPage from '../components/ProcessPage.vue';
import AllProjects from '../components/AllProjects.vue';
import VueProjects from '../components/Projects/VueProjects.vue';
import AllProcesses from '../components/AllProcesses.vue';


const routes = [
    {
        path: "/",
        name: "About",
        component: AboutComponent,
    },
    {
        path: "/Process",
        name: "Process",
        component: AllProcesses,
    },
    {
        path: "/Projects",
        name: "Projects",
        component: AllProjects,
    },
    {
        path: "/VueProjects",
        name: "VueProjects",
        component: VueProjects,
    },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  //goes to top of routed page when page is changed
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  }
})

export default router