<template>
<div class="flex-row" id="top-content">
  <div>
    <router-link to="/"><img src="../assets/kb-logo-cropped.png" class="logo-mini"></router-link>
    <!-- <div id="monochrome-theme-selector">
      theme buttons go here -->
      <!-- I'm trying to target the child of the div being clicked -->
        <!-- <ThemeButton
        title="monochrome"
        color="black" />
    </div>
    <div @click="peachyToggle = !peachyToggle, peachyThemeSelector()">
      <ThemeButton
        title="peachy"
        color="#FFA699" />
    </div>
    <div @click="seafoamToggle = !seafoamToggle, seafoamThemeSelector()">
        <ThemeButton
        title="seafoam"
        color="#9CEAD2" />
    </div> -->
  </div> 
    <span class="nav-menu" @click="navtoggle = !navtoggle">
      <img src="../../public/Projects-assets/themes/monocrome/planet-icon.png" class="nav-icon"/>
      <!-- website-test\public\Projects-assets\themes\monocrome\planet-icon.png -->
      <h4 class="nav-text">navigate</h4>
    </span>
  </div>
  <!-- navigation -->
  <div class="nav-dropdown" v-show="navtoggle">
    <div id="navbar">
        <ul class="nav-links">
            <li>
              <router-link to="/">Profile</router-link>
            </li>
            <li>
              <router-link to="/Process">Process</router-link>
            </li>
            <li>
              <router-link to="/Projects">Projects</router-link>
            </li>
            <li>
              <a href="mailto:kirstyn.blandy@kblandy.com">Contact</a>
            </li>
        </ul>    
    </div>
  </div>
<!-- /navigation -->
  <div class="main" id="main-content">
      <img src="../assets/logo-with-text-cropped.png" class="logo-main">
    <div class="subtext">
      <h3 class="slogan">dream</h3>
      <img src="../../public/Projects-assets/themes/monocrome/diamond-icon.png" class="diamond-icon">
      <h3 class="slogan">design</h3>
      <img src="../../public/Projects-assets/themes/monocrome/diamond-icon.png" class="diamond-icon">
      <h3 class="slogan">develop</h3>
    </div>
<!-- alternating Main content routed here -->
    <div class="main-viewport" id="main-viewport">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
// import ThemeButton from './ThemeButton.vue';
export default {
  data: () => ({ 
    navtoggle: false,
    monochromeToggle: true,
    peachyToggle: false,
    seafoamToggle: false,
  }),
  name: 'MainPage',
  components: {
    // ThemeButton,
  },
  methods: {
    peachyThemeSelector() {
      // console.log('it worked');
      // let test = document.getElementById('monochrome-theme-selector');
      // console.log(test)
      if (this.peachyToggle) {
        let newTheme = document.getElementById("main-viewport").classList.add("peachy-theme");
        return newTheme; 
      } if (!this.peachyToggle) {
        let newTheme = document.getElementById("main-viewport").classList.remove("peachy-theme");
        return newTheme;
    }
  },
    seafoamThemeSelector() {
      if (this.seafoamToggle) {
        let newTheme = document.getElementById("top-content").classList.add("seafoam-theme");
        let newTheme2 = document.getElementById("main-viewport").classList.add("seafoam-theme");
        return newTheme, newTheme2; 
      } if (!this.seafoamToggle) {
        let newTheme = document.getElementById("top-content").classList.remove("seafoam-theme");
        let newTheme2 = document.getElementById("main-viewport").classList.remove("seafoam-theme");
        return newTheme, newTheme2;
      }
    },
  }
};
</script>

<style lang="scss" scoped>
//sass typefaces
$primary-font: 'Roboto Mono', monospace;
$font-special: 'Urbanist', sans-serif;
$font-typewriter: 'Courier Prime', monospace;

//sass colors
$primary-color: black;
$secondary-color: gray;
$accent-color: rgb(49, 49, 49);

// T O G G L E D THEMES HERE
//monochrome theme
$monochrome-color: white;
//peachy theme
$peachy-color: #FFA699;
//seafoam theme
$seafoam-color: #9CEAD2;

// theme classes
.monochrome-theme {
  background-color: $monochrome-color;
}
.peachy-theme {
  background-color: $peachy-color;
}
.seafoam-theme {
  background-color: $seafoam-color;
}
//resume other CSS
ul, li {
    text-decoration: none;
    list-style: none;
    text-align: right;
    font-size: 10px;
}
li {
  margin: 12px;
  // border: 1px dotted black;
  padding: 10px;
  font-family: $font-typewriter;
  background-color: white;
  box-shadow: 1px 1px 1px 1px $secondary-color;
}
ul {
 justify-content: end;
 margin-top: -10px;
}
.nav-links {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  margin-right: 10px;
}
.nav-text {
  margin-top: -5px;
}
.diamond-icon {
  width: 8px;
  height: 10px;
  padding: 20px;
  opacity: .5;
}
.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  left: 10px;
  right: 10px;
  top: 0px;
}
.nav-icon {
  width: 40px;
  height: auto;
  margin-top: -10px;
  margin-left: 10px;
}
.nav-menu {
  display: flex;
  flex-direction: column;
  justify-content: centered;
  width: auto;
  height: auto;
  font-family: $font-typewriter;
  padding-top: 6px;
  font-weight: bolder;
  letter-spacing: .5px;
  font-size: 8px;
}
.logo-mini {
  width: 20px;
  height: 20px;
  padding-top: 12px;
}
.logo-main {
  height: 100px;
  margin: auto;
  padding: 0;
  z-index: 50;
}
.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 40px;

  font-size: 10px;
  font-family: $font-typewriter;
}
// MAIN VIEWING PORT
.main-viewport {
  width: 80%;
  background-repeat: repeat-x;
  border-top: double;
  margin: auto;
}

.nav-dropdown {
  font-family: $font-typewriter;
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  text-align: right;
  padding-top: 10px;
}
.slogan {
  font-weight: 450;
  letter-spacing: 2px;
  z-index: 9;
  font-size: 10px;
  padding-top: 8px;
}
.subtext {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 12px;
  margin: auto;
  padding-left: 18px;
  font-family: $primary-font;
  width: 75%;
}
</style>
